<template>
  <div class="index">
    <!-- index首页头部 -->
    <div class="indexHeader">
      <!-- 头部组件 -->
      <Header></Header>
      <div class="text1">挖掘看不见世界中的价值</div>
      <div class="text2">
        新基建背景下重新定义工业互联网 赋能工业企业数字化升级
      </div>
    </div>
    <!-- index中间内容 -->
    <div class="indexCenter">
      <!-- 行业工业互联网平台 -->
      <div class="platform">
        <div class="centerTitle">行业工业互联网平台</div>
        <div class="centerContent">
          面向产业集群，通过各类机器设备、人、业务系统的互联，促进数据跨系统、端到端的流动，基于数据分析、建模、利用，实现数据驱动的生产和运营闭环优
          化，形成新的业务模式和新的业态，向整个行业赋能。
        </div>
        <ul>
          <li v-for="(item, index) in internetPlat" :key="index">
            <a :href="item.href">
              <img :src="item.Pic" alt="" />
              <div class="content">
                <span> {{ item.text }}</span>
                <img class="icon" :src="item.icons" alt="" />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- 解决方案 -->
      <div class="Solution">
        <div class="solutionTitle">解决方案</div>
        <div class="solutionContent">
          帮助企业从客户下单到产品研发设计、原材料采购、生产交付等整个公司运营流程全部实现数字化，将作业控制层、现场管理层、企业营运层、协同商务层整合
          成一个数字化企业圈，实现全价值链精益化。
        </div>
        <ul>
          <li v-for="(item, index) in solutionText" :key="index">
            <p class="ptitle">{{ item.title }}</p>
            <p class="pcontent">{{ item.content }}</p>
          </li>
        </ul>
      </div>
      <!-- 开发者平台 -->
      <div class="developer">
        <h4>开发者平台</h4>
        <div class="developerContent1">
          <img src="~assets/img/index/三象低代码@2x.png" alt="" />
          <div class="devDetail">
            <div class="devNum">ONE</div>
            <p class="devDetailTitle">三象低代码平台</p>
            <p class="devDetailContent">
              三象低代码平台通过组件化、配置化的设计思想，将与应用无关的逻辑全部封装，前后端分离，按需开发，支持与第三方前端和后台的对接，根据需求灵活开发,打造快速开发的平台产品。主要解决软件实施交付中遇到的开发效率低，需求变动频繁，维护成本高，重复开发的问题。
            </p>
            <el-row>
              <router-link :to="{ name: 'LowCodePlatform' }">
                <el-button plain id="indexDevDetail_btn"
                  >了解更多</el-button
                ></router-link
              >
            </el-row>
          </div>
        </div>
        <div class="developerContent2">
          <div class="devDetail">
            <div class="devNum">TWO</div>
            <p class="devDetailTitle">三象IOT平台</p>
            <p class="devDetailContent">
              三象IOT平台提供数据采集、数据传输、边缘计算、大数据等技术实现生产信息透明
              化、提高协同制造能力，从而降低成本、增加效率。
            </p>
            <el-row>
              <router-link :to="{ name: 'IOTPlatform' }">
                <el-button plain id="indexDevDetail_btn"
                  >了解更多</el-button
                ></router-link
              >
            </el-row>
          </div>
          <img src="~assets/img/index/三象IOT@2x.png" alt="" />
        </div>
        <div class="developerContent3">
          <img src="~assets/img/index/三象BI@2x.png" alt="" />
          <div class="devDetail">
            <div class="devNum">THREE</div>
            <p class="devDetailTitle">三象BI平台</p>
            <p class="devDetailContent">
              三象BI平台提供对数据的查询、可视化、预警等核心功能，支持多种数据源接入。可
              以帮助企业分析、探索问题，挖掘数据中的价值，创建清晰、美观的数据看板。
            </p>
            <el-row>
              <router-link :to="{ name: 'BIPlatform' }">
                <el-button plain id="indexDevDetail_btn"
                  >了解更多</el-button
                ></router-link
              >
            </el-row>
          </div>
        </div>
      </div>
      <!-- 精选案例 -->
      <div class="selectCase">
        <h4>精选案例</h4>
        <div class="selectCaseContent">
          <el-tabs type="border-card" id="index_el_tabs">
            <el-tab-pane label="澳亚织造">
              <img
                src="~assets/img/index/AOYA@2x.png"
                alt="AOYA.png"
                class="Pics"
              />
              <div class="selectCaseDetail">
                <p class="selectCaseProduce">
                  2020年，帮助澳亚织造打造东阳市首个5G+工业互联网试点项目，通过现有织造设
                  备传感改造和5G网络建设，构建覆盖主要织造设备的物联网监控系统，实现了设
                  备状态、作业过程、生产量的实时采集和统计，实现覆盖排产、派工、生产执行、
                  检验、设备监控等全流程的数字化管理。
                </p>

                <ul class="caseUl">
                  <li v-for="(item, index) in selectCaseAOYA" :key="index">
                    <img
                      :src="item.img"
                      alt="icon-提升.png"
                      class="devAndRedImg"
                    />
                    <p class="GoodsExam">
                      {{ item.text }} <span class="pre">{{ item.pre }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
            <el-tab-pane label="群力紧固件">
              <img
                src="~assets/img/index/群力紧固件@2x.png"
                alt="AOYA.png"
                class="Pics"
              />
              <div class="selectCaseDetail">
                <p class="selectCaseProduce">
                  2020年，在镇海区工业互联网推进大会上，与宁波群力紧固件制造有限公司签署数
                  字化工厂项目合同。项目基于主要生产设备的数据采集与联网，打造了全过程的数
                  字化管控系统，包括车间资源管理、生产任务管理、车间计划与排产管理、生产过
                  程管理、质量过程管理、物料跟踪管理、车间监控管理、库存管理和统计分析等。
                </p>

                <ul class="caseUl">
                  <li v-for="(item, index) in selectCaseQLJGJ" :key="index">
                    <p class="GoodsExam">
                      {{ item.text }}
                    </p>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
            <el-tab-pane label="东政电机">
<img
                src="~assets/img/index/公司@2x.png"
                alt="AOYA.png"
                class="Pics"
              />
              <div class="selectCaseDetail">
                <p class="selectCaseProduce">
                  2021年5月，与浙江东政电机有限公司签署数字化工厂项目合作协议，通过对精工车间、装配车间的加工机床、装配生产线等设备进行数据采集与联网，有效支持设备的可视化管理、提升设备生产利用率。
                </p>

                <ul class="caseUl">
                  <li v-for="(item, index) in selectCaseDZ" :key="index">
                    <p class="GoodsExam">
                      {{ item.text }}
                    </p>
                  </li>
                </ul>
              </div>

            </el-tab-pane>
            <el-tab-pane label="金鑫海绵">
              <img
                src="~assets/img/index/客户@2x.png"
                alt="AOYA.png"
                class="Pics"
              />
              <div class="selectCaseDetail">
                <p class="selectCaseProduce">
                  2020年，联合岱山县金鑫海绵制品有限公司打造了数字化工厂项目。通过工厂全面集成系统建立一个从计划管理、采购管理、仓储管理、生产管理、质量控制等全面集成的系统，系统中拥有规范化的管理和体系，丰富的生产分析类型，让管理者和生产者实时掌握生产动态。
                </p>

                <ul class="caseUl">
                  <li v-for="(item, index) in selectCaseJX" :key="index">
                    <p class="GoodsExam">
                      {{ item.text }}
                    </p>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 底部组件 -->
    <Footer></Footer>
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/index/icon-回到顶部@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>

<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "index",
  data() {
    return {
      internetPlat: [
        {
          Pic: require("assets/img/index/线带纺织行业工业互联网平台@2x.png"),
          text: "线带纺织行业工业互联网平台",
          icons: require("assets/img/index/icon-下一页@2x(1).png"),
          href: "https://fzxd.dtsum.com/#/index"
        },
        {
          Pic: require("assets/img/index/紧固件行业工业互联网平台@2x.png"),
          text: "紧固件行业工业互联网平台",
          icons: require("assets/img/index/icon-下一页@2x(1).png"),
          href: "http://jgj.dtsum.com/#/"
        },
        {
          Pic: require("assets/img/index/汽船配行业工业互联网平台@2x.png"),
          text: "汽船配行业工业互联网平台",
          icons: require("assets/img/index/icon-下一页@2x(1).png"),
          href: "http://qp.dtsum.com/#/"
        }
      ],
      solutionText: [
        {
          title: "纺织行业数字化工厂解决方案",
          content:
            "面向线带纺织行业企业，覆盖排产、派工、生产执行、检验、设备监控等全流程的数字化管理。"
        },
        {
          title: "紧固件行业数字化工厂解决方案",
          content:
            "面向紧固件生产企业，打造ERP + MES + WMS 铁三角组合，实现从销售订单到发货的全流程数字化管控。"
        },
        {
          title: "汽船配行业数字化工厂解决方案",
          content:
            "帮助汽船配行业建立一个以离散制造管理和标准成本管理为核心的管理体系，实现从订单管理、采购管理、仓储管理、生产管理、等全面集成的系统。"
        }
      ],
      selectCaseAOYA: [
        {
          img: require("assets/img/index/icon-提升@2x(1).png"),
          text: "挡车工效率提升",
          pre: "30%"
        },
        {
          img: require("assets/img/index/icon-提升@2x(1).png"),
          text: "设备利用率提升",
          pre: "30%"
        },
        {
          img: require("assets/img/index/icon-减少@2x(1).png"),
          text: "生产的周期缩短",
          pre: "10%"
        },
        {
          img: require("assets/img/index/icon-减少@2x(1).png"),
          text: "原材料浪费减少",
          pre: "10%"
        }
      ],
      selectCaseQLJGJ: [
        { text: "自动计算成本和交期" },
        { text: "提升效率和产出，缩减成本" },
        { text: "数字化经营" }
      ],
      selectCaseDZ: [
        { text: "设备建模" },
        { text: "设备管理" },
        { text: "状态监控" },
        { text: "数据采集" },
        { text: "数据分析" },
      ],
      selectCaseJX: [
        { text: "全面集成系统" },
        { text: "降低库存成本" },
        { text: "掌握生产动态 " },
        { text: "辅助决策  " },
      ],
      
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scoped>
.index {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // index首页头部样式
  .indexHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/index/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
    .text1 {
      width: 422px;
      height: 38px;
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      line-height: 25px;
      color: #ffffff;
      text-shadow: 0px 5px 6px rgba(30, 144, 255, 0.45);
      margin-top: 171px;
      margin-left: 140px;
      opacity: 1;
    }
    .text2 {
      width: 303px;
      height: 56px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 36px;
      color: rgba(255, 255, 255, 0.85);
      opacity: 1;
      margin-top: 25px;
      margin-left: 140px;
    }
  }
  // index中间内容
  .indexCenter {
    background-color: #f8f9fb;
    padding-top: 80px;
    // 行业工业互联网平台模块样式
    .platform {
      .centerTitle {
        width: 254px;
        height: 28px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin: 0 auto;
      }
      .centerContent {
        width: 976px;
        height: 44px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin: 28px auto 0;
        text-align: left;
      }
      ul {
        width: 1220px;
        height: 310px;
        display: flex;
        margin: 30px auto 0;
        li {
          width: 394px;
          height: 310px;
          list-style: none;
          margin-right: 19px;
          background-color: #fff;
          img {
            width: 100%;
            height: 235px;
          }
          .content {
            display: flex;
            align-items: center;

            span {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 580;
              line-height: 75px;
              color: #329aff;
              opacity: 1;
              margin-left: 30px;
            }
            .icon {
              display: block;
              width: 26px;
              height: 26px;
              margin-left: 78px;
            }
          }
        }
      }
    }
    // 解决问题模块样式
    .Solution {
      width: 1220px;
      height: 378px;
      background: url("~assets/img/index/BG-解决方案@2x.png") no-repeat;
      background-position: center;
      background-size: 1220px 378px;
      margin: 80px auto 0;
      overflow: hidden;
      .solutionTitle {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin-top: 40px;
        text-align: center;
      }
      .solutionContent {
        width: 990px;
        height: 40px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: #333333;
        opacity: 1;
        margin: 28px auto 0;
      }
      ul {
        width: 1160px;
        height: 178px;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        li {
          width: 364px;
          height: 178px;
          background: #fff;
          .ptitle {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 27px;
            color: #333333;
            opacity: 1;
            margin: 30px auto 20px;
            text-align: center;
          }
          .pcontent {
            width: 304px;
            height: 80px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            color: rgba(51, 51, 51, 0.65);
            opacity: 1;
            margin: 0 auto;
          }
        }
      }
    }
    // 开发者平台样式
    .developer {
      width: 1220px;
      margin: 0 auto;
      h4 {
        width: 142px;
        height: 28px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin: 80px auto 40px;
      }
      .developerContent1 {
        width: 1220px;
        height: 300px;
        background: linear-gradient(
          315deg,
          rgba(243, 248, 253, 0.6) 0%,
          rgba(232, 243, 254, 0.6) 100%
        );
        border-radius: 10px;
        display: flex;
        margin-bottom: 30px;
        img {
          width: 295px;
          height: 260px;
          margin: 20px 181px;
        }
        .devDetail {
          .devNum {
            width: 39px;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.1);
            opacity: 1;
            margin-top: 26px;
            text-align: left;
          }
          .devDetailTitle {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            opacity: 1;
            margin: 20px 0 20px;
          }
          .devDetailContent {
            width: 528px;
            height: 100px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.65);
            opacity: 1;
          }
        }
      }
      .developerContent2 {
        box-sizing: border-box;
        width: 1220px;
        height: 300px;
        background: linear-gradient(
          135deg,
          rgba(243, 248, 253, 0.6) 0%,
          rgba(232, 243, 254, 0.6) 100%
        );
        border-radius: 10px;
        display: flex;
        margin-bottom: 30px;
        padding-left: 67px;
        img {
          width: 332px;
          height: 283px;
          margin: 9px 139px 8px;
        }
        .devDetail {
          .devNum {
            width: 39px;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.1);
            opacity: 1;
            margin-top: 26px;
            text-align: left;
          }
          .devDetailTitle {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            opacity: 1;
            margin: 20px 0 20px;
          }
          .devDetailContent {
            width: 528px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.65);
            opacity: 1;
          }
        }
      }
      .developerContent3 {
        width: 1220px;
        height: 300px;
        background: linear-gradient(
          315deg,
          rgba(243, 248, 253, 0.6) 0%,
          rgba(232, 243, 254, 0.6) 100%
        );
        border-radius: 10px;
        display: flex;
        img {
          width: 358px;
          height: 250px;
          margin: 20px 149px 30px;
        }
        .devDetail {
          .devNum {
            width: 39px;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.1);
            opacity: 1;
            margin-top: 26px;
            text-align: left;
          }
          .devDetailTitle {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            opacity: 1;
            margin: 20px 0 20px;
          }
          .devDetailContent {
            width: 528px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.65);
            opacity: 1;
          }
        }
      }
    }
    // 精选案例
    .selectCase {
      margin-bottom: 101px;
      h4 {
        width: 112px;
        height: 28px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        margin: 80px auto 40px;
      }
      .selectCaseContent {
        width: 1220px;
        height: 426px;
        margin: 0 auto;
        .el-tabs {
          .el-tab-pane {
            display: flex;
            .Pics {
              width: 600px;
              height: 300px;
              margin: 30px;
            }
            .selectCaseDetail {
              width: 510px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .selectCaseProduce {
                width: 510px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 28px;
                color: rgba(51, 51, 51, 0.85);
              }
              .caseUl {
                width: 590px;
                // margin:-2px 0 0 0;
                padding: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                li {
                  width: auto;
                  height: 42px;
                  padding: 0 12px;
                  background: #e1f1ff;
                  border-radius: 8px;
                  display: flex;
                  margin-right: 24px;
                  margin-bottom: 20px;
                  .devAndRedImg {
                    width: 18px;
                    height: 18px;
                    margin: 12px 6px 11px 0px;
                  }
                  .GoodsExam {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Bold;
                    color: #1e90ff;
                    line-height: 12px;
                    letter-spacing: 1px;
                    display: flex;
                    span {
                      font-size: 18px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #1e90ff;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
// 开发者平台按钮
#indexDevDetail_btn {
  width: 105px;
  height: 42px;
  background: #1e90ff;
  border-radius: 8px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
  margin-top: 15px;
}
.el-tabs__item {
  font-size: 18px !important;
}
</style>
